import { addLocaleData, IntlProvider } from 'react-intl'
import enMessages from 'languages/en'
import { errorHandler } from 'store/logger'
import _ from 'lodash'

export const LOAD_TRANSLATIONS = 'load.translations'

// ------------------------------------
// Helpers
// ------------------------------------
export const getIsLanguageRtl = language => {
	return language === 'ar'
}

export const stubIntl = () => {
	if (!window.Intl) {
		// fallback for iOS and IEs where intl is not supported
		require('intl')
		require('intl/locale-data/jsonp/en.js')
		window.Intl.fallback = true
	} else {
		window.Intl.fallback = false
	}
}
stubIntl()

const processRow = (key, value) => `${key} {${value}}`

const createIntlSelect = (translationsObject, defaultTranslation) => {
	const processedRows = Object.entries(translationsObject).map(([key, value]) =>
		processRow(key, value),
	)
	const processedRowsAsString = processedRows.join('\n')

	return `{ gsIdStudyObject, select, ${processedRowsAsString} other {${defaultTranslation}} }`
}

export const loadTranslations = (language, customTranslations = null) => (dispatch, getState) => {
	dispatch({
		type: LOAD_TRANSLATIONS,
		response: { language, customTranslations },
	})
}

const createTranslations = (language, translations) => {
	let messages = translations

	/*
	 * create proxy object so we can access which translations are requested and log if there is something missing
	 * proxy is not supported in every browser (IE, safari..) so we do this only if there is Proxy object on window
	 */
	if (typeof window.Proxy !== 'undefined') {
		messages = new Proxy(translations, {
			get: (target, name) => {
				if (
					(typeof target[name] === 'undefined' && // requested translation doesn't exists in translated file
						name !== 'toJSON' &&
						name !== '@@toStringTag') ||
					name === target[name]
				) {
					// dispatch and getState are not available at this point so we are using anonymous functions
					errorHandler(new Error('Missing translation'), { language, name })(
						() => {},
						() => {},
					)
				}

				return target[name]
			},
		})
	}

	let intlProvider = new IntlProvider({ locale: language, messages }, {})
	let formatMessage = intlProvider.getChildContext().intl.formatMessage

	return {
		language,
		messages,
		formatMessage,
	}
}

// ------------------------------------
// initialState
// ------------------------------------
const initialState = createTranslations('en', enMessages)

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[LOAD_TRANSLATIONS]: (state, action) => {
		let language = action.response.language || 'en'
		let customTranslations = action.response.customTranslations || null
		if (language !== state.language || customTranslations !== null) {
			try {
				// require default translation file
				let translations = require('languages/' + language + '.js').default

				if (customTranslations !== null) {
					// merge default translations with customTranslations,
					// - customTranslations keys are overriding keys in translations

					Object.entries(customTranslations).forEach(([key, value]) => {
						if (_.isString(value) === true) {
							translations[key] = value
						} else {
							const defaultTranslation = translations[key]
							translations[key] = createIntlSelect(value, defaultTranslation)
						}
					})
				}

				if (window.Intl.fallback === true) {
					let languageFile = 'intl/locale-data/jsonp/' + language + '.js'
					require(languageFile)
				} else {
					let locale = require('react-intl/locale-data/' + language)
					addLocaleData(locale)
				}

				// this creates new state for intl
				return createTranslations(language, translations)
			} catch (e) {
				// log error and stay with current language
				// dispatch and getState are not available at this point so we are using anonymous functions
				errorHandler(new Error('Language not supported'), { language: action.response.language })(
					() => {},
					() => {},
				)
				return state
			}
		}

		// requested translation is already loaded
		return state
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function intlReducer(state = initialState, action) {
	let actionHandler = action.type
	const handler = ACTION_HANDLERS[actionHandler]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Getters
// ------------------------------------
export const formatMessage = (messageId, props = {}) => (dispatch, getState) => {
	let state = getState()
	props.gsIdStudyObject = state.study.studyObject.id
	let translation = state.intl.formatMessage({ id: messageId }, props)

	return translation
}

export const getLanguage = state => {
	return state.intl.language
}

export const getIsStudyRtl = state => {
	return getIsLanguageRtl(state.intl.language)
}
