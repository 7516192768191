import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'
import { addMessage, addAction, addTempMessage } from 'store/queue'
import { createComponent, getCurrentMessage, removeTempMessages } from 'store/_chat/history'
import { DELAY_LENGTH } from 'constants/delay'
import { COMPONENTS, POSITION } from 'constants/component'
import { CHOICE_QUESTION_LOG_ACTIONS } from 'constants/choiceQuestionLog'
import { MODULE_TYPES } from 'constants/moduleTypes'
import { formatMessage, getIsStudyRtl, loadTranslations } from 'store/intl'
import { buttonStateUpdateListener } from './_tools/button'
import { setTimerListeners, setTimerInterval, timerReset } from 'store/_chat/timer'

import {
	setOptionsChangeListener,
	actionsShow,
	actionsHide,
	getActionOptions,
	setRecordLogItem,
} from 'store/_chat/actions'
import {
	setActionSubmitListener,
	showActionLoader,
	showActionButton,
	showActionHint,
} from 'store/_chat/actionBar'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'

import { ACTION_BUTTON_STATE, ACTION_BUTTON_LOADER_TYPE } from 'constants/actionButton'
import { CHOICE_SUBTYPE } from 'constants/choice'

import { getChoiceInstructionArgs } from 'helpers/getChoiceInstructionArgs'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'
import { API } from 'store/api'
import { setStudyLanguage } from 'store/study'

// experimental
const BLOCK_TIMEOUT = 8000

// ------------------------------------
// Actions
// ------------------------------------
export const RECORD_CHOICE_LOG_ACTION = 'choice.log.record.action'
export const RESET_CHOICE_LOG = 'choice.log.reset'

// ------------------------------------
// Listeners
// ------------------------------------

// experimental
let canSubmitChoice = true

const isExperimentalQuestion = (idStudy, idStudyObject) => {
	if (idStudy === '67fe757b-ffd1-4212-aed1-a630fa814317') {
		return [
			'c81f0b6c-6299-418c-b9cb-091fe291454c',
			'3ba198b4-d692-4f06-a4aa-27390b7c9276',
		].includes(idStudyObject)
	}

	if (idStudy === 'ba4c204c-e521-47ee-8722-e5a4cb845d63') {
		return idStudyObject === '6e77e198-05c7-4e4b-b5f1-1fd4062f1c0b'
	}

	return false
}

// experimental
export const updateListener = (currentTime, initialTime) => (dispatch, getState) => {
	const timeElapsed = currentTime - initialTime

	const isTimerFinished = timeElapsed >= BLOCK_TIMEOUT

	if (isTimerFinished === true) {
		canSubmitChoice = true
	}

	const studyObject = getStudyObject(getState())
	const optionsSelected = getState().chatActions.options.filter(option => option.selected === true)
	const hasOptionSelected = optionsSelected.length > 0

	const buttonStateAfterTimer =
		hasOptionSelected === true ? ACTION_BUTTON_STATE.SUBMIT_READY : ACTION_BUTTON_STATE.DEFAULT

	const selectOptionTranslaton = formatMessage(...getChoiceInstructionArgs(studyObject.definition))(
		dispatch,
		getState,
	)

	const submitButtonText =
		hasOptionSelected === true
			? formatMessage('action_input_submit_button_text')(dispatch, getState)
			: selectOptionTranslaton

	buttonStateUpdateListener(
		BLOCK_TIMEOUT,
		null,
		timeElapsed,
		submitButtonText,
		false,
		buttonStateAfterTimer,
	)(dispatch, getState)
}

// ------------------------------------
// Primary Actions
// ------------------------------------
export const init = definition => (dispatch, getState) => {
	// listeners setup
	setOptionsChangeListener(optionsChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setActionSubmitListener(primarySubmitListener, secondarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setRecordLogItem(recordChoiceLogItem)(dispatch, getState) // eslint-disable-line no-use-before-define

	const options = definition.options.map(option => ({
		...option,
		selected: false,
	}))

	// experimental
	const { studyObject } = getState().study
	const idStudy = studyObject.idStudy
	const idStudyObject = studyObject.id

	const isThatQuestion = isExperimentalQuestion(idStudy, idStudyObject)

	if (isThatQuestion === true) {
		timerReset()(dispatch, getState)
		setTimerListeners(updateListener)(dispatch, getState)
		setTimerInterval(BLOCK_TIMEOUT)(dispatch, getState)

		canSubmitChoice = false
	}

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showStep(definition, options)(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

const recordChoiceLogItem = (timestamp, idStudyObject, studyObjectType, action, data = null) => (
	dispatch,
	getState,
) => {
	if (studyObjectType === MODULE_TYPES.CHOICE) {
		dispatch({
			type: RECORD_CHOICE_LOG_ACTION,
			choiceLogAction: { timestamp, idStudyObject, studyObjectType, action, data },
		})
	}
	// TODO
	// log OEQ_ELIMINATE options change to elaborate store
	// log OEQ_ELABORATE options change to elaborate store
}

export const resetChoiceLog = () => (dispatch, getState) => {
	dispatch({
		type: RESET_CHOICE_LOG,
	})
}

export const showStep = (definition, options) => (dispatch, getState) => {
	const { multiple, showLabels } = definition

	switch (definition.subtype) {
		case CHOICE_SUBTYPE.IMAGE:
			addAction(
				actionsShow,
				[CHOICE_SUBTYPE.IMAGE, options, multiple, showLabels],
				DELAY_LENGTH.SHORT,
			)(dispatch, getState)
			break
		case CHOICE_SUBTYPE.CHECKBOX:
			addAction(
				actionsShow,
				[CHOICE_SUBTYPE.CHECKBOX, options, multiple],
				DELAY_LENGTH.SHORT,
			)(dispatch, getState)
			break
		case CHOICE_SUBTYPE.BLOCK:
			addAction(
				actionsShow,
				[CHOICE_SUBTYPE.BLOCK, options, multiple],
				DELAY_LENGTH.SHORT,
			)(dispatch, getState)
			break
		default:
			break
	}

	const solverMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

	if (definition.mandatory === false) {
		const skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	} else {
		const hintText = formatMessage(...getChoiceInstructionArgs(definition))(dispatch, getState)

		addAction(showActionHint, [hintText])(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			const instruction = createComponent(COMPONENTS.INSTRUCTION)

			instruction.props.text = hintText

			addTempMessage(instruction, solverMessagePosition, 0)(dispatch, getState)
		}
	}

	const studyObject = getStudyObject()(dispatch, getState)
	const currentMessage = getCurrentMessage()(dispatch, getState)

	recordChoiceLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		CHOICE_QUESTION_LOG_ACTIONS.OPTIONS_DISPLAYED,
		{ options, currentMessage },
	)(dispatch, getState)
}

export const endStep = (selectedOptions, props) => (dispatch, getState) => {
	removeTempMessages()(dispatch, getState)

	const response = createComponent(COMPONENTS.MESSAGE)
	response.props = Object.assign({}, response.props, props)

	const studyObject = getStudyObject()(dispatch, getState)

	// create apiResponse
	const studyObjectResponse = {
		[studyObject.id]: null,
	}

	if (selectedOptions) {
		const responseIds = selectedOptions.map(option => option.id)
		studyObjectResponse[studyObject.id] = responseIds
	}

	recordChoiceLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		CHOICE_QUESTION_LOG_ACTIONS.SUBMIT,
		studyObjectResponse[studyObject.id],
	)(dispatch, getState)

	const studyObjectActionLog = getState().choice.choiceLog

	// add currentMessage to action log if it's missing
	// currentMessage is saved to store after OPTIONS_DISPLAYED action
	// when refreshing the page
	const optionsDisplayedAction = studyObjectActionLog.find(
		item => item.action === CHOICE_QUESTION_LOG_ACTIONS.OPTIONS_DISPLAYED,
	)

	if (_.isEqual(optionsDisplayedAction.data.currentMessage, {}) === true) {
		optionsDisplayedAction.data.currentMessage = getCurrentMessage()(dispatch, getState)
	}

	const messagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT

	addMessage(
		response,
		messagePosition,
		DELAY_LENGTH.LONG,
		studyObjectResponse[studyObject.id],
	)(dispatch, getState)

	resetChoiceLog()(dispatch, getState)

	// push answer to server
	return getState().study.getNextStep(
		studyObjectResponse,
		studyObjectActionLog,
		true,
	)(dispatch, getState)
}

// ------------------------------------
// Listeners
// ------------------------------------

export const optionsChangeListener = options => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let selectedOptions = options.filter(option => option.selected)

	// experimental
	if (canSubmitChoice === false) {
		return
	}

	if (definition.multiple === true) {
		handleMultiple(selectedOptions, definition)(dispatch, getState) // eslint-disable-line no-use-before-define
	} else if (definition.multiple === false) {
		handleSingle(selectedOptions, definition)(dispatch, getState) // eslint-disable-line no-use-before-define
	}
}

export const primarySubmitListener = () => (dispatch, getState) => {
	actionsHide()(dispatch, getState)
	showActionLoader()(dispatch, getState)

	let definition = getStudyObjectDefinition()(dispatch, getState)
	let options = getActionOptions()(dispatch, getState)
	let selectedOptions = options.filter(option => option.selected)

	// build thumbs if image choice
	let messageThumbs
	if (definition.subtype === CHOICE_SUBTYPE.IMAGE) {
		messageThumbs = selectedOptions.map(option => {
			let thumb = createComponent(COMPONENTS.MESSAGE_THUMB)
			thumb.url = option.media.url
			thumb.label = option.label
			return thumb
		})
	}

	let messageText = selectedOptions.map(option => option.label).join(', ')
	let props = {
		text: messageText,
		media: null,
		thumbs: messageThumbs,
	}

	return endStep(selectedOptions, props)(dispatch, getState).then(apiResponse => {
		if (apiResponse.type === API.GET_NEXT_STEP.SUCCESS) {
			if (definition.isLanguageChoice === true) {
				// TODO: What to do when language is undefined?
				const language = selectedOptions[0].languageCode

				loadTranslations(language)(dispatch, getState)
				setStudyLanguage(language)(dispatch, getState)
			}
		}
	})
}

export const secondarySubmitListener = () => (dispatch, getState) => {
	let props = {}
	props.text = formatMessage('general_skip_action_message')(dispatch, getState)

	endStep(null, props)(dispatch, getState)
}

// ------------------------------------
// Helper Actions
// ------------------------------------

export const handleSingle = (selectedOptions, definition) => (dispatch, getState) => {
	let buttonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	if (selectedOptions.length === 1) {
		// enable submit button
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, buttonText)(dispatch, getState)
	} else if (selectedOptions.length === 0) {
		if (definition.mandatory === true) {
			showActionHint(formatMessage('choice_single_hint')(dispatch, getState))(dispatch, getState)

			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(formatMessage('choice_single_hint')(dispatch, getState))(
					dispatch,
					getState,
				)
				publishAccessibilityHint()(dispatch, getState)
			}
		} else if (definition.mandatory === false) {
			// enable skip button
			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
					dispatch,
					getState,
				)
				publishAccessibilityHint()(dispatch, getState)
			}

			let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)
			showActionButton(ACTION_BUTTON_STATE.SKIP_READY, skipButtonText)(dispatch, getState)
		}
	}
}

export const handleMultiple = (selectedOptions, definition) => (dispatch, getState) => {
	let buttonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
	let isNoneOfTheseSelected = selectedOptions.find(option => option.isNoneOfThese === true)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	if (isNoneOfTheseSelected) {
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, buttonText)(dispatch, getState)
	} else if (selectedOptions.length === 0) {
		// handle zero selected
		if (definition.mandatory === true) {
			const hintText = formatMessage(...getChoiceInstructionArgs(definition))(dispatch, getState)

			showActionHint(hintText)(dispatch, getState)

			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(hintText)(dispatch, getState)
				publishAccessibilityHint()(dispatch, getState)
			}
		} else if (definition.mandatory === false) {
			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
					dispatch,
					getState,
				)
				publishAccessibilityHint()(dispatch, getState)
			}

			let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)
			showActionButton(ACTION_BUTTON_STATE.SKIP_READY, skipButtonText)(dispatch, getState)
		}
	} else if (selectedOptions.length >= definition.minSelection) {
		// handle more than min selected
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, buttonText)(dispatch, getState)
	} else {
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(
				formatMessage('n_of_min_selected', {
					selected: selectedOptions.length,
					min: definition.minSelection,
				})(dispatch, getState),
			)(dispatch, getState)
			publishAccessibilityHint()(dispatch, getState)
		}

		// handle less than min selected
		let percent = (selectedOptions.length / definition.minSelection) * 100
		showActionButton(
			ACTION_BUTTON_STATE.SUBMIT_PREP,
			buttonText,
			ACTION_BUTTON_LOADER_TYPE.PERCENT,
			percent,
		)(dispatch, getState)
	}
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	choiceLog: [],
	idIteration: uuidv4(),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[RECORD_CHOICE_LOG_ACTION]: (state, action) => {
		const newState = { ...state }
		newState.choiceLog = state.choiceLog.slice()
		newState.choiceLog.push({ idIteration: state.idIteration, ...action.choiceLogAction })
		return newState
	},
	[RESET_CHOICE_LOG]: (state, action) => {
		const newState = { ...state }
		newState.choiceLog = []
		newState.idIteration = uuidv4()
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
